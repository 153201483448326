/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.gallery-item img').matchHeight();
        $('.showcase-alternative').matchHeight();
        // $('.top-section img').matchHeight();
        $('.card-alt').matchHeight();

        $( window ).on( "load", function() {
          $('.header-nav').scrollToFixed({
            minWidth : 767
          });
        });



        // Close ipad menus on body click
        $(document).click( function(){
          $('.sub-menu').removeClass('ipadhover');
        });

        // ipad sub menu link clicks
        if ($(window).width() > 767) {
          $("header .menu-item-has-children > a").on("touchend", function (e) {
            e.preventDefault();
            var link = $(this).attr("href");
            if ($(this).parent().find('.sub-menu').hasClass('ipadhover')) {
              window.location.href = link;
            } else {
              $('.sub-menu').removeClass('ipadhover');
              $(this).parent().find('.sub-menu').addClass('ipadhover');
            }
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.flexslider').flexslider({
            animation: "fade",
            controlNav: true,
            animationSpeed: 1200,
            slideshowSpeed: 5000
        });

        /* Clirent Logos Swiper*/
        var mySwiper = new Swiper ('.swiper-container', {
          // Optional parameters
          direction: 'horizontal',
          loop: true,

          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },

          slidesPerView: 4,
          spaceBetween: 50,

          breakpoints: {
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact_us': {
      init: function () {
        var map;
        var mapcenter = {lat: 56.4799791, lng: -3.0308837};

        map = new google.maps.Map(document.getElementById('map'), {
          center: mapcenter,
          zoom: 8
        });

        var marker = new google.maps.Marker({
          position: mapcenter,
          map: map,
          url: 'https://www.google.co.uk/maps/place/Dovetail+Enterprises+1993+Ltd/@56.4799791,-3.0308837,14z/data=!4m6!3m5!1s0x48864329dd3d1d6b:0xee3df9147025022a!4b1!8m2!3d56.4799791!4d-3.0133742'
        });
      }
    },
    'our_clients': {
      init:function(){
        $('.logo-item').matchHeight();
      }
    },
    'clients': {
      init:function(){
        $('.case-study__info h3').matchHeight();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
